var footerdom = {
	texts: {
		text1:
			'Sie erhalten diese Email, weil Sie unsere Dienste in Anspruch nehmen oder',
		text2:
			'an Gewinnspielen der The Performance Network Group GmbH oder unserer Kooperationspartner teilgenommen haben.',
		text3:
			'Die Angebote sind kommerzielle Anzeigen der jeweiligen Werbekunden, die f&uuml;r den jeweiligen Inhalt verantwortlich sind. Regressanspr&uuml;che gegen die The Performance Network Group GmbH sind ausgeschlossen. Sollten Sie Fragen zu den Angeboten haben, wenden Sie sich bitte an den in der Anzeige genannten Anbieter. Die The Performance Network Group GmbH ist ausschlie&szlig;lich Versender dieser Nachricht.',
		text4:
			'Diese Nachricht wurde automatisch generiert. Bitte auf diese Mail nicht antworten.',
		text5:
			'Sofern Sie den aktuellen',
		text6:
			'-Newsletter nicht mehr erhalten wollen, k&ouml;nnen Sie sich ',
		address1: 'ist ein Service der:',
		address2: 'The Performance Network Group GmbH',
		address3: 'Gro&szlig;e Theaterstra&szlig;e 7',
		address4: 'D-20354 Hamburg',
		address5: 'Gesch&auml;ftsf&uuml;hrung: David Mermelstein',
		address6:
			'Informationen zur Datenverarbeitung gem&auml;&szlig; Art. 13 DS-GVO finden Sie',
		address7: 'E-Mail: userservice@tpng.de',
		address8: 'Amtsgericht Hamburg, HRB 139417',
		address81: 'USt-ID-Nr.: DE245334954',
		address9: 'Fax: +49 40 890 66 33 -33',

		firm: 'Aktienjournal.de',
	},
	link1: 'signoutlink',
	link1text: 'hier abmelden',
	imprint: 'Impressum',
	imprintlink: 'imprint',
	addresslink1: 'https://www.tpng.de/datenschutz/',
	addresslink1text: 'hier',
	style: {
		background: '#ffffff',
		color: '#666666',
		fontsize1: '10px',
		fontsize2: '12px',
		linkcolor: '#1C2762',
		fontweight: 'normal',
		lineheight: '15px',
		fontfamily: 'Verdana, Arial, Helvetica, sans-serif',
	}
};

var footertemplate = [
	'',
	'',
	'',
	'',
	'',
	'',
	'',
	'<!--[Online_Mailing_Block]-->',
	'<!--[if mso]>',
	'<center><table><tr><td width="600">',
	'<![endif]-->',
	'<table bgcolor="{{{ style.background }}}" style="max-width:600px;" border="0" cellspacing="0" cellpadding="0" align="center">',
	'<tr>',
	'<td align="center" style="padding-left:10px;padding-right:10px;color: {{{ style.color }}}; font-family: {{{ style.fontfamily }}}; font-size: {{{ style.fontsize1 }}};">',
	'<br />{{{ texts.text1 }}}<br />',
	'{{{ texts.text2 }}}<br />',
	'{{{ texts.text3 }}}<br />',
	'{{{ texts.text4 }}}<br />',
	'{{{ texts.text5 }}}',
	'{{{ texts.firm }}}'+'{{{ texts.text6 }}}',
	'<strong><a href="{{{ link1 }}}" style="color:{{{ style.linkcolor }}};">{{{ link1text }}}</a></strong>.<br />',
	'<br />',
	'<strong><a href="{{{ imprintlink }}}" style="color: {{{ style.linkcolor }}}; font-size: {{{ style.fontsize2 }}};">Impressum</a></strong><br />',
	'{{{ texts.firm }}} {{{ texts.address1 }}}<br />',
	'{{{ texts.address2 }}}<br />',
	'{{{ texts.address3 }}}<br />',
	'{{{ texts.address4 }}}<br />',
	'<br />',
	'{{{ texts.address7 }}}<br />',
	'{{{ texts.address9 }}}<br />',
	'{{{ texts.address5 }}}<br />',
	'<br />',
	'{{{ texts.address8 }}}<br />',
	'{{{ texts.address81}}}<br />',
	'<br />',
	'{{{ texts.address6 }}} <a href="{{{ addresslink1 }}}" target="_blank">{{{ addresslink1text }}}</a>.<br /><br />',
	'</td>',
	'</tr>',
	'</table>',
	'<!--[if mso]>',
	'</td></tr></table></center>',
	'<![endif]-->',
	'<!--[/Online_Mailing_Block]-->',
	'',
	'',
	'',
	'',
	'',
	'',
	'',
].join('\n');

export { footerdom, footertemplate };
