var headerdom = {
	texts: {
		text1:
		"Sollte diese E-Mail nicht einwandfrei dargestellt werden, klicken Sie bitte",
		text2: "Sie k&ouml;nnen sich",
		text3: ", sofern Sie den",
		text4: "-Newsletter nicht mehr erhalten wollen.",
		firm: "Aktienjournal.de"
	},
	links: {
		link1: "#T{htmlview}T#",
		link1text: "hier",
		link2: "signoutlink",
		link2text: "hier abmelden",
	},
	style: {
		color: "#000000",
		background: '#ffffff',
		fontsize: "11px",
		linkcolor: "#1C2762",
		fontweight: "normal",
		lineheight: " 15px",
		fontfamily: "Verdana, Arial, Helvetica, sans-serif",
	},
	mailingBlockTdStyle : `<center><table style="background:#ffffff;background-color:#ffffff;width:100%;"><tr><td width="600">`,
};
var headertemplate = [
	'',
	'',
	'',
	'',
	'',
	'',
	'',
	'<!--[Online_Mailing_Block]-->',
	'<!--[if mso]>',
	'{{{ mailingBlockTdStyle }}}',
	'<![endif]-->',
	'<table bgcolor="{{{ style.background }}}" width="100%" style="max-width:600px;" border="0" cellspacing="0" cellpadding="0" align="center">',
	'<tr>',
	'<td align="center">',
	'<span style="color: {{{ style.color }}}; font-size: {{{ style.fontsize }}}; text-decoration: none; font-weight: {{{ style.fontweight }}}; font-family: {{{ style.fontfamily }}}">',
	'{{{ texts.text1 }}} <a href="{{{ links.link1 }}}" style="font-family: {{{ style.fontfamily }}}; color: {{{ style.linkcolor }}}; line-height: {{{ style.lineheight }}};">{{{ links.link1text }}}</a>.',
	'<br />',
	'{{{ texts.text2 }}} <strong><a href="{{{ links.link2 }}}" style="color: {{{ style.linkcolor }}};">{{{ links.link2text }}}</a></strong>{{{ texts.text3 }}} {{{ texts.firm }}}{{{ texts.text4 }}}</span>',
	'</td>',
	'</tr>',
	'</table>',
	'<!--[if mso]>',
	'</td></tr></table></center>',
	'<![endif]-->',
	'<!--[/Online_Mailing_Block]-->',
	'',
	'',
	'',
	'',
	'',
	'',
	'',
].join('\n');

export { headerdom, headertemplate };
